// BottomNavigation.jsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { matchPath, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';

import NeedAuth from '../container/NeedAuth.js';
import MeAvatar from '../container/MeAvatar.js';

import { getIsIphoneXSeriesPWA } from '../resource/getUserAgent.js';
import { ButtonId } from '../resource/mixpanel.js';
import withNotificationPaddingMonitor from '../resource/withNotificationPaddingMonitor.js';
import { SHORT_PATHS } from '../resource/getShouldShowFooter.js';
import getNavigationIcon, {
  NavigationId,
} from '../resource/getNavigationIcon.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import {
  color,
  textColor,
  footerNavigationHeight,
  iPhoneXSeriesFooterPaddingInPWA,
} from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import media from '../style/media.js';

import FreeBadgeIconSource from '../../img/img_badge_free.svg';
import AddIconSource from '../../img/ic_add_white_xs.svg';

import usePreviousValue from '../hook/usePreviousValue.js';
import { useTranslation } from 'react-i18next';

const ICON_SIZE = 28; // TODO: remote config
const MAX_MILLISECONDS = 500; // TODO: remote config
const randomMilliseconds = Math.floor(Math.random(0, 1) * MAX_MILLISECONDS);

export const BottomNavigation = ({
  hasUnread = false,
  isAccountLinksFetching = false,
  isAccountLinksFetched = false,
  isAuthed = false,
  hasUnsetItemOnManageProfile = true,
  isShortEnabled = true,
  login = () => null,
  fetchAccountLinks: getAccountLinks = () => null,
  fetchUnreadChatrooms = () => null,
  apiCacheTimestamp = 0,
  latestFeedRenewUnix = 0,
  isHamburgerMenuOpen = false,
  openHamburgerMenu = () => null,
  openUploadMenuModal = () => null,
  closeHamburgerMenu = () => null,
  fetchFeeds = () => null,
  renewFeedId = null,
  feedNameWithQuery = null,
  browseTabs = [],
  clipCategory = null,
  subscribeFeedChannel = () => null,
  unsubscribeFeedChannel = () => null,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isServer, setIsServer] = useState(true);
  const prevApiCacheTimestamp = usePreviousValue(apiCacheTimestamp);
  const clipCategoryRef = useRef(clipCategory);
  const itemData = useMemo(() => {
    return [
      {
        id: NavigationId.HOME,
        linkTo: '/',
        pathArray: [
          '/',
          '/home',
          '/freezone',
          '/story',
          ...browseTabs.map(tab => `/${tab}`),
        ],
        isNeedAuth: false,
        buttonId: ButtonId.Tab.TabButtonHome,
        buttonNameI18nKey: 'tabbar_home',
      },
      // {
      //   id: 'livestream',
      //   linkTo: '/livestream',
      //   pathArray: ['/livestream'],
      //   isNeedAuth: false,
      //   // TODO: should change to correct button id.
      //   buttonId: ButtonId.Tab.TabButtonHome,
      //   buttonNameI18nKey: 'button_entrance_stream',
      // },
      // {
      //   id: 'event',
      //   linkTo: '/events/lottery',
      //   pathArray: ['/events'],
      //   isNeedAuth: false,
      //   buttonId: ButtonId.Tab.TabButtonEvent,
      //   buttonNameI18nKey: 'tab_event',
      // },
      // {
      //   id: 'search',
      //   linkTo: '/search/all',
      //   pathArray: ['/search/flix', '/search'],
      //   isNeedAuth: false,
      //   buttonId: ButtonId.Tab.TabButtonSearch,
      //   buttonNameI18nKey: 'tabbar_discover',
      // },
      {
        id: NavigationId.VIDEO,
        linkTo: '/video',
        pathArray: ['/video'],
        isNeedAuth: false,
        buttonId: ButtonId.Tab.TabButtonDiscover,
        buttonNameI18nKey: 'tabbar_discover',
      },
      {
        id: NavigationId.SHORTS,
        linkTo: '/shorts',
        pathArray: SHORT_PATHS,
        isNeedAuth: false,
        buttonId: ButtonId.Tab.TabButtonShorts,
        buttonNameI18nKey: 'shorts',
      },
      // { id: 'shop' },
      // {
      //   id: 'create',
      //   buttonId: ButtonId.Tab.TabButtonCreate,
      // },
      {
        id: NavigationId.CHAT,
        pathArray: ['/chat'],
        isNeedAuth: false,
        buttonId: ButtonId.Tab.TabButtonChat,
        buttonNameI18nKey: 'tabbar_chat',
      },
      {
        id: NavigationId.ME,
        pathArray: [
          '/u/:username',
          '/u/:username/:listType',
          '/u/:username/:listType/:publishStatus',
          '/user/:userId',
          '/user/:userId/:listType',
          '/user/:userId/:listType/:publishStatus',
        ],
        isNeedAuth: false,
        buttonId: ButtonId.Tab.TabButtonHamburger,
        buttonNameI18nKey: 'tabbar_my',
      },
    ];
  }, [browseTabs]);

  // Subscribe/Unsubscribe clip feed channel
  useEffect(() => {
    if (clipCategory) {
      clipCategoryRef.current = clipCategory;
      subscribeFeedChannel({ feedName: clipCategory });
    }
    return () => {
      unsubscribeFeedChannel({ feedName: clipCategoryRef.current });
    };
  }, [clipCategory, subscribeFeedChannel, unsubscribeFeedChannel]);

  useEffect(() => {
    const nextTick = setTimeout(() => {
      setIsServer(false);
    }, 0);

    return () => {
      clearTimeout(nextTick);
    };
  }, []);

  useEffect(() => {
    const fetchAccountLinks = () => {
      if (!isAccountLinksFetched && !isAccountLinksFetching) {
        getAccountLinks();
      }
    };

    const milliSecs =
      apiCacheTimestamp !== prevApiCacheTimestamp ? randomMilliseconds : 0;

    const nextTick = setTimeout(() => {
      if (isAuthed) {
        fetchUnreadChatrooms({ page: 1 });
        fetchAccountLinks();
      }
    }, milliSecs);

    return () => {
      clearTimeout(nextTick);
    };
  }, [
    isAuthed,
    isAccountLinksFetched,
    isAccountLinksFetching,
    getAccountLinks,
    fetchUnreadChatrooms,
    prevApiCacheTimestamp,
    apiCacheTimestamp,
  ]);

  const renderAvatarMenuIcon = ({ isMatch }) => {
    return (
      <MenuIconWrapper isMatch={isMatch}>
        <img
          src={getNavigationIcon({
            navigationId: NavigationId.ME,
            isAuthed: true,
            isMatch,
          })}
          aria-hidden
        />
      </MenuIconWrapper>
    );
  };

  const renderAvatarItem = ({ id, pathArray, buttonId, buttonNameI18nKey }) => {
    const isMatch = pathArray.some(path => matchPath(path, pathname));

    return (
      <Item key={id}>
        <ItemContent
          $isActive={isMatch || isHamburgerMenuOpen}
          data-element_id={buttonId}
          onClick={() => {
            isHamburgerMenuOpen ? closeHamburgerMenu() : openHamburgerMenu();
          }}
        >
          {isAuthed ? (
            <Avatar $isActive={isMatch || isHamburgerMenuOpen}>
              <MeAvatar
                size={24}
                alt={t(buttonNameI18nKey, {
                  ns: TranslationNamespace.GENERAL,
                })}
              />
              {renderAvatarMenuIcon({
                isMatch: isMatch || isHamburgerMenuOpen,
                id,
              })}
              {hasUnsetItemOnManageProfile && <AvatarRedDot />}
            </Avatar>
          ) : (
            <IconWrapper>
              <Icon
                src={getNavigationIcon({
                  navigationId: id,
                  isMatch: isMatch || isHamburgerMenuOpen,
                  hasUnread,
                })}
                alt={t(buttonNameI18nKey, {
                  ns: TranslationNamespace.GENERAL,
                })}
                aria-hidden
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            </IconWrapper>
          )}
          <MeItemText $isActive={isMatch || isHamburgerMenuOpen}>
            {t(buttonNameI18nKey, {
              ns: TranslationNamespace.GENERAL,
            })}
          </MeItemText>
        </ItemContent>
      </Item>
    );
  };

  const renderNavItem = ({
    id,
    linkTo,
    pathArray,
    linkTitle,
    isNeedAuth = true,
    buttonId,
    buttonNameI18nKey,
  }) => {
    const path = linkTo || `/${id}`;
    const isMatch = pathArray.some(path => matchPath(path, pathname));
    const isHome = '/' === path;
    const content = (
      <IconWrapper>
        <Icon
          src={getNavigationIcon({
            navigationId: id,
            isMatch,
            hasUnread,
          })}
          alt={t(buttonNameI18nKey, {
            ns: TranslationNamespace.GENERAL,
          })}
          aria-hidden
          title={linkTitle || id}
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
        {id === NavigationId.SHORTS && (
          <FreeBadge
            src={FreeBadgeIconSource}
            width="26"
            height="10"
            alt="free badge"
          />
        )}
      </IconWrapper>
    );

    const link = isNeedAuth ? (
      <NeedAuth>
        <ItemContent
          $isActive={isMatch}
          data-element_id={buttonId}
          onClick={() => {
            isHamburgerMenuOpen && closeHamburgerMenu();
          }}
          as={Link}
          to={path}
          data-key="authed"
        >
          {content}
          <ItemText $isActive={isMatch}>
            {t(buttonNameI18nKey, {
              ns: TranslationNamespace.GENERAL,
            })}
          </ItemText>
        </ItemContent>
        <ItemContent data-key="authing" $isActive={isMatch}>
          {content}
          <ItemText $isActive={isMatch}>
            {t(buttonNameI18nKey, {
              ns: TranslationNamespace.GENERAL,
            })}
          </ItemText>
        </ItemContent>
        <ItemContent
          $isActive={isMatch}
          data-key="needAuth"
          data-element_id={buttonId}
          onClick={() => {
            return login();
          }}
        >
          {content}
          <ItemText $isActive={isMatch}>
            {t(buttonNameI18nKey, {
              ns: TranslationNamespace.GENERAL,
            })}
          </ItemText>
        </ItemContent>
      </NeedAuth>
    ) : (
      <ItemContent
        $isActive={isMatch}
        data-element_id={buttonId}
        onClick={() => {
          isHamburgerMenuOpen && closeHamburgerMenu();
          if (isMatch && isHome) {
            fetchFeeds({
              id: renewFeedId,
              type: feedNameWithQuery,
              page: 1,
              unixTimestamp: latestFeedRenewUnix,
            });
          }
        }}
        as={Link}
        to={path}
        data-key={id}
      >
        {content}
        <ItemText $isActive={isMatch}>
          {t(buttonNameI18nKey, {
            ns: TranslationNamespace.GENERAL,
          })}
        </ItemText>
      </ItemContent>
    );

    return <Item key={id}>{link}</Item>;
  };

  const renderCreateItem = ({ id, buttonId }) => (
    <Item key={id}>
      <CreateButton
        data-element_id={buttonId}
        onClick={() => {
          return openUploadMenuModal();
        }}
      >
        <img src={AddIconSource} alt="create button" />
      </CreateButton>
    </Item>
  );

  return (
    <StyledBottomNavigation
      isIphoneXSeriesPWA={!isServer && getIsIphoneXSeriesPWA()}
    >
      <List>
        {itemData.map(item =>
          item.id === NavigationId.ME
            ? renderAvatarItem(item)
            : item.id === NavigationId.CREATE
              ? renderCreateItem(item)
              : item.id === NavigationId.SHORTS
                ? isShortEnabled
                  ? renderNavItem(item)
                  : null
                : renderNavItem(item)
        )}
      </List>
    </StyledBottomNavigation>
  );
};

const Avatar = styled.div`
  position: relative;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ $isActive }) =>
    $isActive ? color.tealBlue : color.black};
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
`;

const AvatarRedDot = styled.div`
  position: absolute;
  top: -2px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${color.red};
  border: solid 2px ${color.black};
`;

BottomNavigation.propTypes = {
  hasUnread: PropTypes.bool,
  isAccountLinksFetching: PropTypes.bool,
  isAccountLinksFetched: PropTypes.bool,
  isAuthed: PropTypes.bool,
  hasUnsetItemOnManageProfile: PropTypes.bool,
  isShortEnabled: PropTypes.bool,
  login: PropTypes.func,
  fetchAccountLinks: PropTypes.func,
  fetchUnreadChatrooms: PropTypes.func,
  apiCacheTimestamp: PropTypes.number,
  latestFeedRenewUnix: PropTypes.number,
  isHamburgerMenuOpen: PropTypes.bool,
  openHamburgerMenu: PropTypes.func,
  closeHamburgerMenu: PropTypes.func,
  openUploadMenuModal: PropTypes.func,
  fetchFeeds: PropTypes.func,
  feedNameWithQuery: PropTypes.string,
  browseTabs: PropTypes.array,
  renewFeedId: PropTypes.string,
  clipCategory: PropTypes.string,
  subscribeFeedChannel: PropTypes.func,
  unsubscribeFeedChannel: PropTypes.func,
};

const StyledBottomNavigation = styled.nav`
  border-top: 1px solid #232323;
  height: ${({ isIphoneXSeriesPWA }) =>
    isIphoneXSeriesPWA
      ? footerNavigationHeight + iPhoneXSeriesFooterPaddingInPWA
      : footerNavigationHeight}px;
  background-color: #191919;
  display: none;
  ${media.mobile`
    display: block;
  `};
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: 48px;
`;

const Item = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: relative;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const ItemText = styled.div.attrs(({ $isActive }) => ({
  style: {
    color: $isActive ? color.tealBlue : textColor.white,
  },
}))`
  margin-top: 2px;
  font-size: 12px;
`;

const MeItemText = styled(ItemText).attrs(({ $isActive }) => ({
  style: {
    color: $isActive ? color.tealBlue : textColor.white,
  },
}))``;

const CreateButton = styled.button`
  ${ResetButtonStyle};
  position: absolute;
  left: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -8px);
  border-radius: 50%;
  background-color: ${color.tealBlue};

  > img {
    width: 32px;
    height: 32px;
  }
`;

const MenuIconWrapper = styled.div.attrs(({ isMatch }) => ({
  style: {
    backgroundColor: isMatch ? color.tealBlue : color.white,
  },
}))`
  position: absolute;
  bottom: -1px;
  right: -5px;
  border-radius: 50%;
  border: 1px solid ${color.black};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
`;

const FreeBadge = styled.img`
  position: absolute;
  top: -4px;
  right: -6px;
  height: 10px;
  transform: rotate(10deg);
`;

export default withTranslation()(
  withNotificationPaddingMonitor(BottomNavigation)
);
